import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Home';
import reportWebVitals from './reportWebVitals';
// import { UserQuery, UserMutation } from './UserSchema';
// import { SchemaComposer } from 'graphql-compose';

// import apollo client for graphql
// import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

// const schemaComposer = new SchemaComposer();

// schemaComposer.Query.addFields({
//   ...UserQuery,
// });

// schemaComposer.Mutation.addFields({
//   ...UserMutation,
// });

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <ApolloProvider client={clearInterval}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  // </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// export const DbSchema = schemaComposer.buildSchema();
